@import "../../styles/colors.scss";

.classes-section {
  padding-bottom: 2rem;
  
  .date-picker-container {
    padding: 1rem 0;
    box-shadow: 0 0 12px 0px #121212;
  }

  .daily-chapters{
    color: #fff;
    font-size: 1rem;
    text-align: center;
    padding-top: 1rem;
  }

  .carousel-wraper {
    position: relative;
    min-height: 320px;
    .swiper-wrapper {
      gap: 10px;
    }
    .mySwiper {
      z-index: 0;
      padding: 30px;
    }
    .spinner {
      position: absolute;
      top: 0;
      right: 0;
      height: 315px;
      width: 100%;
      display: grid;
      place-content: center;
      z-index: 100;
    }
    .no-lessons {
      color: #fff;
      text-align: center;
      font-size: 17px;
    }
  }

  .Carousel__RailWrapper-sc-hyhecw-1 {
    direction: ltr;
    padding: 2rem 0;

    .Carousel__ItemSet-sc-hyhecw-3 {
      display: flex !important;
      justify-content: center;

      .Carousel__Item-sc-hyhecw-5 {
        max-width: 200px;
      }
    }
  }
}

@media only screen and (max-width: 580px) {
  .classes-section {
    .daily-chapters{
      font-size: 1.2rem;
    }
  }
}

@media only screen and (max-width: 400px) {
  .classes-section {
    .daily-chapters{
      font-size: 1.5rem;
    }
  }
}
