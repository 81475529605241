@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.date-picker {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  color: #fff;

  .nav-btn {
    height: 30px;
  }

  .reactJewishDatePicker {
    font-family: "Alef", sans-serif;
    .monthWrapper {
      right: -47px;
      .weekdayWrapper {
        background-color: $primary;
      }
    }
  }
  .date-display {
    display: flex;
    align-items: center;
    margin-bottom: 0;

    .selectedDate {
      display: flex;
      align-items: center;
      justify-content: left;
      min-width: 150px;
      padding: 0 0.3rem 0 1rem;
      height: 36px;
      font-size: initial;
      .calendarIcon {
        font-size: 20px;
        top: 8px;
      }
    }
  }

  .picked-btn {
    border-color: transparent;
  }
}

@media only screen and (max-width: 580px) {
  .date-picker {
    .nav-btn {
      min-width: unset;
      & > svg {
        height: 1.2em;
        width: 1.2em;
      }
    }
  }
}
@media only screen and (max-width: 400px) {
  .date-picker {
    .nav-btn {
      // min-height: 36px;
      & > svg {
        height: 1.5em;
        width: 1.5em;
      }
    }
  }
}
