@import '../../styles/colors.scss';

.upload-classes {
  .active-class {
    background-color: black;
  }

  .class-details {
    max-width: 850px;
  }
}
.new-class-form {
  text-align: right;
}
