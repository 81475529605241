@import '../../styles/colors.scss';

.track-picker {
  text-align: center;
  padding: 2rem 0;
  background-color: transparent;
  
  .picker-btn {
    font-size: 1.1rem;
    color: #fff;
    border: 1px solid #a1a0a3;
    box-sizing: border-box;
    margin: 15px;
    min-width: 83px;
    overflow: hidden;
    // .soon {
    //   font-size: 10px;
    //   position: absolute;
    //   background: $secondary;
    //   box-shadow: 0 0 0 999px $secondary;
    //   color: $primary;
    //   clip-path: inset(0 -100%);
    //   inset: 0 auto auto 0;
    //   transform-origin: 75% 50%;
    //   transform: translate(-29.3%) rotate(-45deg);
    // }
  }
  
  .picked-btn {
    border-color: transparent;
  }
  br {
    display: none;
  }
}

@media only screen and (max-width: 400px) {
  .track-picker {
    padding: 3rem 0;
    
    .picker-btn {
      font-size: 1.3rem;
      // margin: 10px;
      // width: 55%;
      margin: 10px;

    }
    br {
      display: block;
    }
  }
}
