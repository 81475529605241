@import "../../styles/colors.scss";

.main-menu {
  width: 100%;
  color: $primary;
  padding: 10px;

  .menu-btn {
    margin-right: 1rem;
    .menu-icon {
      font-size: 3rem;
    }
  }
}
.drawer {
  .MuiPaper-root {
    background-color: $primary !important;
    &::-webkit-scrollbar {
      width: .2em;
      // width: 3px;
    }
     
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
     
    &::-webkit-scrollbar-thumb {
      background-color: $secondary;
      outline: 1px solid slategrey;
    }

    .MuiList-root {
      margin: auto;

      .menu-list-item {
        background-color: $secondary;
        width: 50%;
        margin: 4px auto;
        height: 73px;
        padding: 0 10px;

        .MuiTypography-root {
          font-size: 1.2rem !important;
          line-height: 1.3rem;
          font-family: "alef";
          color: $primary;
        }

        .MuiButtonBase-root {
          padding: 0 5px;
        }

        .menu-item-text {
          width: 70%;
        }

        .menu-item-icon {
          width: 30%;
          .menu-icon {
            margin: auto;
            color: #c6beb5;
            font-size: 3rem;
          }
        }
      }

      .admin-btn {
        display: block;
        margin: auto;
      }

      .whatsapp-btn {
        font-size: 2rem;
        display: block;
        margin: auto;
        margin-top: 1rem;
        .whatsapp-icon {
          width: 3rem;
          height: 3rem;
        }
        .whatsapp-text {
          margin-top: -13px;
        }
        &:hover {
          background-color: initial;
        }
      }
    }
    .close-btn {
      display: inline;
      svg {
        font-size: 5rem;
        transition: all 0.4s;
        &:hover {
          color: rgb(254, 254, 220);
        }
      }
      &:hover {
        background-color: initial;
      }
    }
  }
}

@media only screen and (max-width: 580px) {
  .main-menu {
    padding: 5px;
  }
  .drawer {
    .MuiPaper-root {
      &::-webkit-scrollbar {
        width: 0;
      }
      .MuiList-root {
  
        .menu-list-item {
          width: 70%;
  
          .MuiTypography-root {
            font-size: 20px !important;
            line-height: 2rem;
          }
  
          .menu-item-icon {
            .menu-icon {
              font-size: 4rem;
            }
          }
        }
  
        .whatsapp-btn {
          font-size: 4rem;
          .whatsapp-text{
            font-size: 1.3rem;
          }
          .whatsapp-icon {
            width: 4rem;
            height: 4rem;
          }
        }
      }
      
    }
  }
}


@media only screen and (max-width: 450px) {
  .drawer {
    .MuiPaper-root {
      .MuiList-root {
  
        .menu-list-item {
          width: 70%;
  
          .MuiTypography-root {
            line-height: 2rem;
          }
  
          .menu-item-icon {
            .menu-icon {
              font-size: 5rem;
            }
          }
        }
  
        .whatsapp-btn {
          font-size: 5rem;
          .whatsapp-text{
            font-size: 1.6rem;
          }
          .whatsapp-icon {
            width: 5rem;
            height: 5rem;
          }
        }
      }
      
    }
  }
}