@import './styles/colors.scss', './styles/shared.scss', './styles/fonts.scss';

.font-alef, .MuiButtonBase-root, .MuiTypography-root {
  font-family: 'Alef', sans-serif !important;
}

.assistant {
  font-family: 'Assistant', sans-serif !important;
}

.font-secular {
  font-family: 'Secular One', sans-serif !important;
}