.container {
  max-width: 1200px;
  margin: auto;
}

.center-text {
  text-align: center;
}

.pointer {
  cursor: pointer;
}

.input-box {
  position: relative;
  margin-bottom: 10px;
}

.input-err.MuiTypography-root {
  position: absolute;
  right: 0;
  left: 0;
  bottom: -14px;
  margin: 0 8px;
  color: red;
  font-size: .8rem !important;
}

.submit-btn {
  margin: 10px 0 !important;
}

.link {
  color: rgb(62, 107, 255);
}

.flex-space-between {
  display: flex;
  justify-content:space-between;
  align-items: center;
  margin: auto;
}

.center-end {
  display: flex;
  justify-content: end;
  align-items: center;
}