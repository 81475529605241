@import "../../styles/colors.scss";

.header {
  width: 100%;
  background-color: $secondary;
  color: $primary;
  padding: 10px;
  .alert {
    max-width: 500px;
    margin: auto;
    padding: 0 16px;
  }
  .welcome-title {
    text-align: center;
    font-size: 1.5rem;
    margin: 0;
  }
  .bsd {
    color: $primary;
    text-align: center;
    font-size: 0.7rem;
  }

  .logo-text {
    text-align: center;
    font-size: 3rem;

    img {
      margin-top: 1rem;
      max-width: 8rem;
    }
  }

  .ear {
    position: fixed;
    top: 50%;
    right: 50%;
    animation: ear 1s infinite ease-in-out;
    z-index: 10000;
  }
}

@keyframes ear {
  0% {
    width: 20px;
  }
  50% {
    width: 30px;
  }
  100% {
    width: 20px;
  }
}
