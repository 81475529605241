@import "../../styles/colors.scss";

.dedication {
  background-color: #3a3a52;
  width: 100%;
  padding: 2rem;
  color: #c4c4d4;
  text-align: center;
  box-sizing: border-box; 
  p {
    margin: 0.1rem;
    font-size: 0.8rem;
  }
}
