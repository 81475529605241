@import "../../styles/colors.scss";

.class-card {
  background-color: $primary !important;
  width: 200px;
  .card-content{
    padding: 16px 0;
  }
  .avatar-container {
    margin: 3rem auto 0;
    width: 7rem !important;
    height: 7rem !important;
    position: relative;

    .avatar {
      margin: auto;
      width: 7rem !important;
      height: 7rem !important;
    }

    .media-icon {
      position: absolute;
      bottom: -0.8rem;
      left: 0.2rem;
      color: #bcd0ec;
      font-size: 2rem;
    }
  }

  .class-metadata {
    display: flex;
    justify-content: center;
    color: #aba9a4;
    gap: 10px;
    margin-bottom: 1rem;
  }

  .lecturer-name {
    font-size: 1rem !important;
    color: #fff;
  }
}

@media only screen and (max-width: 580px) {
  .class-card  {
    width: 150px;
    .lecturer-name {
      font-size: 1.2rem !important;
    }
  }
}

@media only screen and (max-width: 400px) {
  .class-card  {
    padding: 0 10px;
    width: 130px;
    .avatar-container {
      margin: 4rem auto 0;
      width: 9rem !important;
      height: 9rem !important;
  
      .avatar {
        width: 9rem !important;
        height: 9rem !important;
      }
  
      .media-icon {
        bottom: -0.8rem;
        left: 0.2rem;
        font-size: 3rem;
      }
    }
    
    .lecturer-name {
      font-size: 1.5rem !important;
    }
  }
}