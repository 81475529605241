@import '../../styles/colors.scss';

.user-profile {
  max-width: 1000px;
  margin: auto;
  padding: 10px;

  .upload {
    font-family: 'Alef', sans-serif;
    font-size: 1.2rem;
  }

  .file-input {
    display: none;
  }

}
