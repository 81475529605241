@import "../../styles/colors.scss";

.basic-class-card {
  background-color: $primary !important;
  &.active {
    box-shadow: 0px 0px 10px 2px #b8b6b6;
    border: 2px solid $primary;
  }
  .card-header {
    color: #fff;
    text-align: right;
    height: 2rem;
    padding: 0.2rem 1rem;
    .MuiTypography-root {
      font-size: 24px;
    }
  }
  
  .card-content {
    // font-size: 1rem !important;
    background-color: #fff;
  }
}
