@import "./styles/fonts.scss";


html {
  font-size: 16px;
}
body {
  margin: 0;
  font-family: 'Alef', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  width: .4em;
}
 
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: #3a3a52;
  outline: 1px solid slategrey;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: inherit;
}
@media only screen and (max-width: 580px) {
  html {
    font-size: 12px;
  }
}
@media only screen and (max-width: 450px) {
  html {
    font-size: 10px;
  }
}
@media only screen and (max-width: 400px) {
  html {
    font-size: 8px;
  }
}
